import React from 'react';
import Img from "gatsby-image"

import { Link } from 'gatsby';

const ProductCard = ({ title, description, url, imageFixed, className }) => (
  <div className='px-3 mb-6'>
    <Link to={url} >
      <div
        className={`rounded-lg border border-solid border-gray-200 ${className}`}
        style={{
          boxShadow: '0 10px 28px rgba(0,0,0,.08)',
        }}
      >
        <Img fixed={imageFixed} />
        <div className={`p-6`}>
          <p className="text-xl font-semibold">{title}</p>
          <p className="mt-6">{description}</p>
        </div>
      </div>
    </Link>
  </div>

);

export default ProductCard;
