import React from 'react';
import { graphql } from "gatsby"
import { Link } from 'gatsby';
import Layout from '../components/layout/Layout';
import ProductCard from '../components/ProductCard';

export default ({ data }) => {
  return (
    <Layout>
      <section id="services" className="py-50 lg:pb-10 lg:pt-12">
        <div className="container mx-auto text-center">
          <Link to="/demo">Back to demo</Link>
          <h2 className="text-3xl lg:text-5xl font-semibold">Marketplace</h2>
        </div>
      </section>
      <section id="services" className="py-50 lg:pb-10 lg:pt-12">
        <div className="container mx-auto">
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12 flex-wrap">
            <ProductCard
              title="Beds"
              description="3 hospital beds"
              imageFixed={data.hospitalBeds.childImageSharp.fixed}
              url="/products/bed"
            />
            <ProductCard
              title="Crutches"
              description="A pair of crutches"
              imageFixed={data.crutches.childImageSharp.fixed}
              url="/products/bed"
            />
            <ProductCard
              title="Microscope"
              description="A Microscope"
              imageFixed={data.microscope.childImageSharp.fixed}
              url="/products/chair"
            />
            <ProductCard
              title="Monitor"
              description="Hospital Monitor"
              imageFixed={data.or.childImageSharp.fixed}
              url="/products/bed"
            />
            <ProductCard
              title="Surgical Equipment"
              description="Equipment for surgeries"
              imageFixed={data.surgicalEquipment.childImageSharp.fixed}
              url="/products/bed"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    crutches: file(relativePath: { eq: "crutches.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hospitalBeds: file(relativePath: { eq: "hospital-beds.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hospitalChair: file(relativePath: { eq: "hospital-chair.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    microscope: file(relativePath: { eq: "microscope.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    or: file(relativePath: { eq: "or.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    surgicalEquipment: file(relativePath: { eq: "surgical-equipment.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
